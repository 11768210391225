<template>
  <v-row>
    <v-card
      class="text-card bordered-card mx-auto text-white py-6 px-12 my-6 mx-12"
      color="#26c6da"
      max-width="450"
      min-height="500"
      prepend-icon="mdi-security"
      title="Segurança"
    >
      <template v-slot:prepend>
        <v-icon size="x-large"></v-icon>
      </template>
  
      <v-card-text class="text-card text-h5 py-12">
        Graças aos recursos avançados de segurança do Linux, o Policorp Linux é resistente a vírus e malware. As correções de segurança e atualizações de software são disponibilizadas rapidamente e estão disponíveis por muitos anos.
      </v-card-text>
    </v-card>
    <v-card
      class="text-card bordered-card mx-auto text-white py-6 px-12 my-6 mx-12"
      color="green"
      max-width="450"
      min-height="500"
      prepend-icon="mdi-speedometer"
      title="Velocidade"
    >
      <template v-slot:prepend>
        <v-icon size="x-large"></v-icon>
      </template>
  
      <v-card-text class="text-card text-h5 py-12">
        Seu computador deve funcionar tão rapidamente quanto você. O Policorp Linux é extremamente ágil e não perde velocidade com o tempo. Os aplicativos abrem rapidamente, permitindo que você passe mais tempo sendo produtivo.
      </v-card-text>
    </v-card>
    <v-card
      class="text-card bordered-card mx-auto text-white py-6 px-12 my-6 mx-12"
      color="grey"
      max-width="450"
      min-height="500"
      prepend-icon="mdi-laptop"
      title="Compatibilidade"
    >
      <template v-slot:prepend>
        <v-icon size="x-large"></v-icon>
      </template>
  
      <v-card-text class="text-card text-h5 py-12">
        O Policorp Linux é compatível com uma ampla variedade de hardware, tornando-o excepcionalmente versátil e adaptável a diferentes configurações de computadores e dispositivos.
      </v-card-text>
    </v-card>
  </v-row>
</template>

<style scoped>
.text-card {
  font-family: 'Roboto', sans-serif;
  line-height: 35px;
  font-weight: grey;
}

.bordered-card {
  border: 2px solid white;
}
</style>
