<template>
  <container fluid>
    <v-row align="center" class="d-flex justify-center download-section">
      <v-col cols="12" md="6" class="d-flex justify-center">
        <div class="left-widget">
          <v-img src="../assets/computers.png" class="d-none d-sm-none" max-width="500" max-height="350" alt="Image"></v-img>
          <h1>Policorp Linux</h1>
          <h3>Com uma base sólida e eficiente, Policorp Linux oferece um desempenho veloz e estável para todas as suas tarefas diárias.</h3>
          <v-row justify="center pt-12">
            <v-btn @click="scrollToForm" color="secondary mr-2">Baixe Agora</v-btn>
          </v-row>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="right-widget d-none d-md-flex">
          <v-img src="../assets/computers.png" max-width="500" max-height="350" alt="Image"></v-img>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="showForm" align="center" class="info-section justify-center mb-12">
      <v-col cols="12" md="8" lg="6">
        <v-row class="mb-4">
          <v-col>
            <h2 class="text-center">
              Queremos que você aproveite ao máximo o sistema, preencha o formulário para fazer o download e receber informações e novidades sobre o Policorp Linux.
            </h2>
          </v-col>
        </v-row>

        <v-form v-if="!submited" v-model="form" @submit.prevent="submitForm" ref="form">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="formData.nome"
                label="Nome"
                :rules="[required]"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formData.email"
                :readonly="loading"
                :rules="[required, emailValidationRule]"
                class="mb-2"
                label="Email"
                clearable
                required
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2">
              <v-text-field
                v-model="formData.ddd"
                label="DDD"
                :rules="[required, dddValidationRule]"
                @keypress="filter"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10">
              <v-text-field
                v-model="formData.telefone"
                label="Telefone"
                @keypress="filter"
                placeholder="9 dígitos"
                :rules="[required, phoneValidationRule]"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center overflow-auto recommended">
            <!-- Minimum Requirements -->
            <v-col cols="12" md="6">
              <v-card class="elevation-10 mb-6">
                <v-card-title class="headline text-center">Requisitos Mínimos Recomendados</v-card-title>
                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Processador</v-list-item-title>
                      <v-list-item-subtitle>1 GHz Dual Core – Intel/AMD 64-bit</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>RAM</v-list-item-title>
                      <v-list-item-subtitle>1 GB RAM para 32-bit; 2 GB para 64-bit</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Armazenamento</v-list-item-title>
                      <v-list-item-subtitle>16 GB de disco disponível para 32-bit; 20 GB for 64-bit</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>

            <!-- Recommended Requirements -->
            <v-col cols="12" md="6">
              <v-card class="elevation-10 mb-6">
                <v-card-title class="headline text-center">Requisitos Recomendados</v-card-title>
                <v-divider></v-divider>

                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Processador</v-list-item-title>
                      <v-list-item-subtitle>2 GHz processador dual-core </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>RAM</v-list-item-title>
                      <v-list-item-subtitle>4 GB RAM</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Armazenamento</v-list-item-title>
                      <v-list-item-subtitle>SSD com 32 GB de disco</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" cols="12" class="mb-12">
              <v-btn type="submit" color="primary">Enviar</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div v-else>
          <h1>O download não iniciou? Tente novamente clicando <a :href="downloadLink" download>aqui</a></h1>
        </div>
      </v-col>
    </v-row>
    <v-row align="left" class="info-section justify-center mb-12">
      <v-col cols="12">
        <div class="info-title">
          <h2 class="mb-3">Por que escolher o Policorp Linux?</h2>
        </div>
        <h4 class="info-section-black pb-12">
          <v-icon color="success">mdi-check-circle</v-icon> Desenvolvimento, manutenção e suporte técnico nacional, realizado por profissionais experientes e certificados.<br>
          <v-icon color="success">mdi-check-circle</v-icon> Pronto para usar, o Policorp Linux traz uma seleção de aplicativos para atender as necessidades dos usuários, com editor de texto, planilha eletrônica, editor de apresentação, editor fotos, navegador de internet, cliente de e-mail e muito mais.<br>
          <v-icon color="success">mdi-check-circle</v-icon> O Policorp Linux em sua versão OEM já está presente em vários computadores de grandes fabricantes.<br>
          <v-icon color="success">mdi-check-circle</v-icon> O Kernel (núcleo) do sistema é desenvolvido e mantido diretamente pela Policorp, garantindo melhor compatibilidade aos componentes de hardware já certificados.<br>
          <v-icon color="success">mdi-check-circle</v-icon> Versões LTS (Long Term Support) com manutenção e suporte para 5 anos ou mais.<br>
          <v-icon color="success">mdi-check-circle</v-icon> Instalação segura com secure boot habilitado por padrão, oferecendo mais segurança ao sistema.<br>
          <v-icon color="success">mdi-check-circle</v-icon> Documentação, o Policorp Linux oferece um guia completo do usuário além de vários vídeos sobre o uso do sistema. Por ser baseado e compatível com o Debian, Ubuntu e derivados, toda documentação e tutoriais desenvolvidos para essas distribuições também podem ser aplicados no Policorp.<br>
        </h4>
      </v-col>
    </v-row>
    
    <v-row align="center" class="info-section justify-center mb-12">
      <v-col cols="12">
        <div class="info-title">
          <h2 class="mb-3">Um ambiente desktop acolhedor <h4>pronto para você!</h4></h2>
        </div>
        <v-img class="pt-12" src="../assets/medium.png"></v-img>
      </v-col>
    </v-row>

    <div class="light-blue-background">
      <v-row align="center" class="info-section justify-center mb-12">
        <v-col cols="12">
          <CardsComponent />
        </v-col>
      </v-row>

      <v-row align="center" class="info-section justify-center">
        <v-col cols="12">
          <div class="info-title">
            <h2 class="mb-3 px-12">Instale seus aplicativos de forma<br> <span>rápida e prática!</span></h2>
          </div>
          <h4 class="text-black pb-12 px-12">Com a nossa poderosa loja de aplicativos, instalar seus programas favoritos é fácil e rápido. <br> Com apenas alguns cliques, você pode encontrar, baixar e instalar uma ampla variedade de aplicativos, tudo de forma intuitiva e sem complicações. <br> Experimente agora mesmo e tenha acesso a milhares de aplicativos em poucos instantes!</h4>
          <div class="apps-image mb-12">
            <v-carousel>
              <v-carousel-item src="../assets/app1.png" cover draggable="true"></v-carousel-item>
              <v-carousel-item src="../assets/app2.png" cover draggable="true"></v-carousel-item>
              <v-carousel-item src="../assets/app3.png" cover draggable="true"></v-carousel-item>
            </v-carousel>
          </div>
        </v-col>
      </v-row>

      <v-row align="center" class="d-flex justify-center office-section mt-12">
        <v-col cols="12" md="6" class="d-flex justify-center">
          <div class="left-widget px-12">
            <OfficeIcons />
          </div>
        </v-col>
        <v-col cols="12" md="6" class="icon-text-container">
          <h2 class="text-white text-center">Compatível com todos os seus documentos e arquivos.</h2>
          <h3 class="text-white text-center">
            Seus documentos, músicas, fotos e vídeos funcionam perfeitamente no Policorp Linux. Além disso, o sistema já vem pré-instalado com a suíte de escritório LibreOffice, que permite visualizar, criar e editar documentos do Microsoft Office/365 de forma totalmente gratuita.
          </h3>
        </v-col>
      </v-row>

    </div>
  </container>
</template>


<script>
import axios from 'axios';
import CardsComponent from './CardsComponent.vue';
import OfficeIcons from './OfficeIcons.vue';



export default {
  components: {
    CardsComponent,
    OfficeIcons,
  },
  data() {
    return {
      showForm: false, 
      formData: {
        nome: '',
        email: '',
        ddd: '',
        telefone: '',
      },
      submited: false,
      loading: false,
      downloadLink: 'policorp-deb12-amd64.iso',
    };
  },

  methods: {
    phoneValidationRule(v) {
      return /^\d{9}$/.test(v) || 'Telefone inválido';
    },
    required(v) {
      return !!v || 'Preencha o Campo';
    },
    dddValidationRule(v) {
      return /^\d{2}$/.test(v) || 'DDD deve ter 2 dígitos';
    },
    emailValidationRule(v) {
      return /.+@.+\..+/.test(v) || 'Email inválido';
    },

    submitForm() {
      if (!this.form) return;
      this.submited = true;

      const formDataToSend = {
        nome: this.formData.nome,
        ddd: this.formData.ddd,
        telefone: this.formData.telefone,
        email: this.formData.email,
        mensagem: '', // Inclui o campo mensagem como uma string vazia
      };

      axios.post('https://api.policorp.com.br/api/customers-linux', formDataToSend)
        .then(response => {
          console.log('Form submitted successfully:', response.data);
          const link = document.createElement('a');
          link.href = this.downloadLink; // Define o caminho do arquivo
          link.click();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          const message = 'Algo deu errado ao fazer a solicitação da imagem. Tente novamente.';
          window.alert(message);
        });
    },

    filter(event) {
      if (!event || !event.charCode) {
        return;
      }

      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar)) {
        event.preventDefault();
      }
    },

    scrollToForm() {
      this.showForm = true; // Exibe o formulário
      this.$nextTick(() => {
        const formElement = this.$refs.form;
        if (formElement) {
          formElement.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: 'SpaceGrotesk-Bold';
  src: url('~@/assets/fonts/SpaceGrotesk-Bold.ttf') format('woff');
  font-weight: 500;
}

.light-blue-background {
  background-color: #32478D; /* Light blue background */
}

.download-section {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding-top: 50px;
  padding-bottom: 180px;
  width: 100vw;
  background: linear-gradient(180deg, #2c428b 0%, #213063 100%);
  border-bottom-left-radius: 100% 45%;
  border-bottom-right-radius: 100% 45%;
}

.download-section::before {
  content: '';
  position: absolute;
  top: 0;
  /* Change top value */
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust height as needed */
  background-image: url('~@/assets/wave1.png');
  /* Path to your image asset */
  background-size: cover;
}


.office-section {
  position: relative;
  padding-top: 20px;
  padding-bottom: 100px;
  width: 100vw;
  background: linear-gradient(180deg, #2c428b 0%, #213063 100%);
  border-top-left-radius: 100% 45%;
  border-top-right-radius: 100% 45%;
}

.office-section::before {
  content: '';
  position: absolute;
  top: 0;
  /* Change top value */
  left: 0;
  width: 100%;
  height: 100%;
  /* Adjust height as needed */
  background-image: url('~@/assets/wave1.png');
  /* Path to your image asset */
  background-size: cover;
}

.info-section {
  font-family: 'SpaceGrotesk-Bold', sans-serif;
  margin-top: 40px;
  text-align: center;
  color: #959497;
  font-size: 1.2rem;
}

.info-title {
  font-size: 2rem;
  line-height: 60px;
}

.left-widget {
  text-align: center;
  /* Center text horizontally */
  color: white;
  width: 600px;
}

.info-section-black {
  position: relative;
  margin-left: 150px;
  margin-right: 80px;
  text-align: justify; /* Justifica o texto */
  line-height: 2.3; /* Add line height for spacing */
}

.info-section-black h4 {
  text-align: center; /* Centraliza o texto dentro da tag <h4> */
}

.right-widget {
  padding-top: 80px;
}

span {
  color: #fefeff;
}

h3 {
  margin: 40px 0 0;
}


ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

.text-black {
  color: rgb(255, 255, 255) !important;
  font-size: 1.2rem;
}

a {
  color: #42b983;
}

.apps-image {
  margin-right: 25vw;
  margin-left: 25vw;
}

.icon-text-container {
  max-width: 550px;
  margin-top: 90px;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1164px) {
  .apps-image {
    margin-left: 0px;
    margin-right: 0px;
  }
  .info-section-black {
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.8; /* Ajuste a altura da linha se necessário */
  }
}

.v-icon.mdi-check-circle {
  color: #4caf50; /* Green color for check-circle icons */
}

.text-black {
  color: #000; /* Ensure text is black for contrast */
}
</style>
