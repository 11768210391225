<template>
  <v-app>
    <v-main>
      <NavigationBar/>
      <router-view/>
      <FooterBar/>
    </v-main>
  </v-app>
</template>

<script>
import NavigationBar from "@/components/NavigationBar";
import FooterBar from "@/components/FooterBar";
export default {
  name: 'App',
  components: {
    NavigationBar,
    FooterBar
  },

  head: {
       link: [
             {
                 rel: "icon",
                 href: require("./assets/brand_logo.png")
             },
          ]
    },


  data: () => ({
  }
  ),
  

  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Download Policorp Linux'; 
    }
  },

  created() {
    document.title = this.$route.meta.title || 'Default Title';
  }
}
</script>
