<template>
  <MainSection/>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import MainSection from '../components/MainSection.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
     MainSection,
  },
});
</script>
