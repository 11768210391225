<template>
  <v-container fluid fill-height>
    <v-row justify="start">
      <v-btn class="ma-2 ml-12 mt-12 mb-12" color="#041a41" @click="goToHome">
        <v-icon color="white" left>mdi-arrow-left
        </v-icon>
        <h3 style="color: white;">Voltar Para Pagina Inicial</h3>
      </v-btn>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="8" lg="6">
        <!-- Message at the top of the form -->
        <v-row class="mb-4">
          <v-col>
            <h2 class="text-center">
              Queremos que você aproveite ao máximo o sistema, preencha o formulário para fazer o download e receber informações e novidades sobre o Policorp Linux.
            </h2>
          </v-col>
        </v-row>

        <v-form v-if="!submited" v-model="form" @submit.prevent="submitForm">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="formData.nome" label="Nome" :rules="[required]"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="formData.email" :readonly="loading" :rules="[required]" class="mb-2" label="Email"
                clearable></v-text-field>
            </v-col>
            <v-col cols="2" sm="2">
              <v-text-field v-model="formData.ddd" label="DDD" :rules="[required, dddValidationRule]"
                @keypress="filter(event)"></v-text-field>
            </v-col>
            <v-col cols="10" sm="10">
              <v-text-field v-model="formData.telefone" label="Telefone" @keypress="filter(event)" placeholder="9 dígitos"

                :rules="[required, phoneValidationRule]"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" cols="12" class="mb-12">
              <v-btn type="submit" color="primary">Enviar</v-btn>
            </v-col>
          </v-row>
        </v-form>
        <div v-else>
            <h1>O download não iniciou? Tente novamente clicando <a :href="downloadLink" download>aqui</a></h1>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center overflow-auto recommended">
      <!-- Minimum Requirements -->
      <v-col cols="12" md="6">
        <v-card class="elevation-10 mb-6">
          <v-card-title class="headline text-center">Requisitos Mínimos Recomendados</v-card-title>
          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Processador</v-list-item-title>
                <v-list-item-subtitle>1 GHz Dual Core – Intel/AMD 64-bit</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>RAM</v-list-item-title>
                <v-list-item-subtitle>1 GB RAM para 32-bit; 2 GB para 64-bit</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Armazenamento</v-list-item-title>
                <v-list-item-subtitle>16 GB de disco disponível para 32-bit; 20 GB for 64-bit</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>

      <!-- Recommended Requirements -->
      <v-col cols="12" md="6">
        <v-card class="elevation-10 mb-6">
          <v-card-title class="headline text-center">Requisitos Recomendados</v-card-title>
          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Processador</v-list-item-title>
                <v-list-item-subtitle>2 GHz processador dual-core </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>RAM</v-list-item-title>
                <v-list-item-subtitle>4 GB RAM</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Armazenamento</v-list-item-title>
                <v-list-item-subtitle>SSD com 32 GB de disco</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" sm="4" class="text-center">
        <v-hover v-slot:default="{ hover }">
          <v-card class="card" :class="{ up: hover }">
            <v-img :src="image" max-width="100px" class="d-block ml-auto mr-auto iso-info"
              :class="{ 'zoom-effect': hover }">
            </v-img>
            <h2 class="mt-4">Sobre o Sistema</h2>
            <h3 class="mt-4">Kernel</h3>
            <span class="mt-4">6.6</span>
            <h3 class="mt-4">Tamanho</h3>
            <span class="mt-4 pb-12">3.6 GB</span>
            <h3 class="mt-4">MD5SUM</h3>
            <span class="mt-4 ">33e3333033b3c7c237d38a3e2ef222 </span>

          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from 'vue';
import axios from "@/utils/axios"

export default defineComponent({
  data() {
    return {
      form: false,
      submited: false,
      downloadLink: 'policorp-deb12-amd64.iso',
      formData: {
        nome: '',
        email: '',
        ddd: '',
        telefone: '',
        mensagem: ''
      },
      image: require('@/assets/computer.png')
    };
  },
  methods: {
    dddValidationRule(v) {
      return /^\d{2}$/.test(v) || 'DDD inválido'
    },
    phoneValidationRule(v) {
      return /^\d{9}$/.test(v) || 'Telefone inválido'
    },
    required(v) {
      return !!v || 'Preencha o Campo'
    },
    goToHome() {
      this.$router.go(-1);
    },
    submitForm() {
      if (!this.form) return
      this.submited = true
      axios.post('/api/customers-linux', this.formData)
        .then(response => {
          console.log('Form submitted successfully:', response.data);
          const link = document.createElement('a');
          link.href = 'policorp-deb12-amd64.iso'; // Set the file path here
          link.setAttribute('policorp-deb12-amd64.iso'); // Set the file name here
          link.click();
        })
        .catch(error => {
          console.error('Error submitting form:', error);
          const message = 'Algo deu errado ao fazer a solicitação da imagem. Tente novamente.';
          window.alert(message);
        });
    },
    filter: function (evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
});
</script>
<style scoped>
.recommended {
  margin-left: 3vw;
  margin-right: 3vw;
}

.recommended .v-list-item__subtitle {
  white-space: unset;
}

.card {
  transition: all 0.3s ease-in-out;
  padding: 15px;
}

.zoom-effect {
  transform: scale(1.1);
}

.iso-info {
  color: black;

}

.text-center{
  color: gray
}
</style>
