<template>
  <v-footer class="fluid-footer">
    <v-card flat tile class="secondary white--text text-center">
      <v-card-text>
        <v-btn v-for="(icon, i) in icons" :key="i" class="secondary mx-4 white--text" :href="icon.link" target="_blank" icon>
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>© Policorp Tecnologia. Todos os direitos reservados.</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
.secondary {
  background-color: #2c428b;
  color: white;
}
.fluid-footer {
  padding: 0;
  margin: 0;
}
</style>

<script>
export default {
  data() {
    return {
      icons: [
        {
          text: "mdi-facebook",
          link: "https://www.facebook.com/policorptecnologia/",
        },
        {
          text: "mdi-linkedin",
          link: "https://www.linkedin.com/company/policorp-tecnologia",
        },
      ],
    };
  },
};
</script>
