<template>
  <DownloadSection/>
</template>

<script>
import { defineComponent } from 'vue';

// Components
import  DownloadSection from '../components/FormSection.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    DownloadSection,
  },
});
</script>
