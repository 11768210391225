<template>
    <v-container fluid class="icon-container">
      <v-row dense>
        <v-col v-for="card in cards" :key="card.title" :cols="card.flex">
          <v-img
            :src="card.src"
            class="align-end icons"
            height="120px"
            cover
            @mouseover="changeTitle(card.title)"
            @mouseleave="resetTitle"
          ></v-img>
        </v-col>
      </v-row>
      <h3 class="text-center title-hover">{{ title }}</h3>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      cards: [
        { title: 'Banco de Dados', src: require('@/assets/libreoffice-base.png'), flex: 3 },
        { title: 'Gerenciador de Tabelas', src: require('@/assets/libreoffice-calc.png'), flex: 3 },
        { title: 'Ferramenta para Desenho', src: require('@/assets/libreoffice-draw.png'), flex: 3 },
        { title: 'Apresentação de Slide', src: require('@/assets/libreoffice-impress.png'), flex: 3 },
        { title: 'Editor de Texto', src: require('@/assets/libreoffice-main.png'), flex: 3 },
        { title: 'Ferramenta para Calculo', src: require('@/assets/libreoffice-math.png'), flex: 3 },
        { title: 'Ferramenta para Digitação', src: require('@/assets/libreoffice-writer.png'), flex: 3 },
        { title: 'Calculadora', src: require('@/assets/accessories-calculator.png'), flex: 3 },
      ],
      title: '', // Initial title
    }),
    methods: {
      changeTitle(newTitle) {
        this.title = newTitle;
      },
      resetTitle() {
        this.title = ''; // Reset title when mouse leaves the icon
      },
    },
  };
  </script>
  
  <style scoped>
  .icon-container {
    padding-top: 150px;
  }
  
  .icons {
    opacity: 0.8;
  }
  
  .icons:hover {
    opacity: 1;
  }

.title-hover {
  position: absolute;
  z-index: 1;
}
  </style>
  