<template>
    <div>
        <div v-if="sidebarVisible" v-click-outside="hideSideBar"    class="sidebar">
            <ul>
                <li><a href="https://policorp.com.br/">Home</a></li>
                <li><a href="https://policorp.com.br/empresa">Empresa</a></li>
                <li><a href="https://policorp.com.br/produtos-e-servicos">Produtos & Serviços</a></li>
                <li><a href="https://policorp.com.br/policorp-linux">Policorp Linux</a></li>
                <li><a href="https://policorp.com.br/poliserv">Poliserv</a></li>
                <li><a href="https://policorp.com.br/polisoft">Polisoft</a></li>
                <li><a href="https://hcl.policorp.com.br/">HCL</a></li>
                <li><a href="https://policorp.com.br/blog">Blog</a></li>
                <li><a href="https://policorp.com.br/faq">FAQ</a></li>
                <li><a href="https://policorp.com.br/trabalhe-conosco">Trabalhe Conosco</a></li>
                <li><a href="https://policorp.com.br/contato">Contato</a></li>
                <li><a style="color: #8facdc" href="https://download.policorp.com.br/">Download</a></li>
            </ul>
        </div>
      <v-row align="center" justify="space-between">
        <v-col cols="1">
          <v-btn
            class="show-on-small"
            color="accent"
            elevation="14"
            icon
            large
            @click="toggleSidebar"
            :class="{ 'increase-margin': sidebarVisible }"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-img src="@/assets/brand_logo.png" class="center mb-12" :width="157"></v-img>
        </v-col>
      </v-row>
  
  
    <v-container v-if="screenWidth >= 864" fluid class="bgColor hide-on-small">
        <div class="horizontal-list">
          <ul>
            <li><a href="https://policorp.com.br/">Home</a></li>
            <li><a href="https://policorp.com.br/empresa">Empresa</a></li>
            <li><a href="https://policorp.com.br/produtos-e-servicos">Produtos & Serviços</a></li>
            <li><a href="https://policorp.com.br/policorp-linux">Policorp Linux</a></li>
            <li><a href="https://policorp.com.br/poliserv">Poliserv</a></li>
            <li><a href="https://policorp.com.br/polisoft">Polisoft</a></li>
            <li><a href="https://hcl.policorp.com.br/">HCL</a></li>
            <li><a href="https://policorp.com.br/blog">Blog</a></li>
            <li><a href="https://policorp.com.br/faq">FAQ</a></li>
            <li><a href="https://policorp.com.br/trabalhe-conosco">Trabalhe Conosco</a></li>
            <li><a href="https://policorp.com.br/contato">Contato</a></li>
            <li><a style="color: #8facdc" href="https://download.policorp.com.br/">Download</a></li>
          </ul>
        </div>
      </v-container>
    </div>
  </template>
   
  <script>
  export default {
    data: () => ({
      sidebarVisible: false,
      screenWidth: window.innerWidth

    }),
    mounted() {
        window.addEventListener('resize', this.updateScreenWidth);
    },
    methods: {
        updateScreenWidth() {
            this.screenWidth = window.innerWidth;
        },
        toggleSidebar() {
            this.sidebarVisible = !this.sidebarVisible;
        },
        hideSideBar() {
            if(this.sidebarVisible == true){
                this.sidebarVisible = false
            }
        },
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.updateScreenWidth);
    }

  }
  </script>
  
  <style scoped>
  a{
    font-family: 'Montserrat', sans-serif;
  }
  .sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #e4e4e7;
  color: black;
  z-index: 999;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  color: black;
  text-decoration: none;
  display: block;
  padding: 10px;
}

.sidebar ul li a:hover {
  background-color: #555;
}
  .v-card {
    width: 100%;
  }
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    width: 50%;
  }
  
  .bgColor {
    z-index: 999;
    background: #2c428b;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.7); /* Adjust the shadow values as needed */
  }
  .horizontal-list {
    display: flex;
    justify-content: center;
  }
  
  .horizontal-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .horizontal-list ul li {
    margin-right: 12px;
  }
  
  .horizontal-list ul li:last-child {
    margin-right: 0;
  }
  
  .horizontal-list ul li a {
    font-size: 17px;
    margin-left: 6px;
    font-weight: 700;
    text-decoration: none;
    line-height: 80px;
    color: white;
  }
  
  .horizontal-list ul li a:hover {
    color: #6296fe;
  }
  
  .show-on-small {
    margin-left: 20px;
    margin-top: 20px;
    display: none;
  }
  .increase-margin {
  margin-left: 210px; /* Adjust the margin as needed */
  z-index: 999;
}
  
  @media (max-width: 1164px) {
    .hide-on-small {
      display: none;
    }
    .show-on-small {
      display: block;
    }
  }
  </style>
  
